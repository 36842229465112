import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firebase_app } from '../../firebase'

export const getLink = createAsyncThunk(
  'web/getLink',
  async (type="popup", thunkAPI) => {
    const response = await firebase_app.firestore().collection('popup').get()
    let result = []
    response.forEach((doc) => {
        result.push(doc.data())
    })
    return {result, type}
  }
)

export const addImgUrl = createAsyncThunk(
  'web/addImg',
  async ({name, imgUrl, type}, thunkAPI) => {
      let db = firebase_app.firestore();
      var response = await db.collection(type).doc(name).set({name, imgUrl})
    return {response}
  }
)


export const webSlice = createSlice({
  name: 'web',
  initialState: {
    data: [],
    type: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [getLink.pending]: (state) => {
      state.isFetching = true;
    },
    [getLink.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.type = payload.type
      const newObj = {}
      for(let i in payload.result) {
        newObj[payload.result[i].name] = payload.result[i].link
      }
      state.data = newObj
    },
    [getLink.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState } = webSlice.actions;

export const webSelector = (state) => state.web;
