import styled from 'styled-components'

export const TableWrapper = styled.table`
  margin: 2em auto;
  /* border: 1px solid black; */

  ${props => {if(props.width < 710){
    return 'width: calc(100% - 180px);'
  } else {
    return 'width: calc(100% - 260px);'
  }}}
  max-width: 1024px;
  border-spacing: 0px;
  table, th, td {
    border: 1px solid black;
    padding: 6px 12px;
  }
  thead {
    border-bottom: 1px solid #f0f0f0;
  }
  input {
    width: 88px;
  }
  .tr-header {
    background: #F7C331;
    td {
      width: 95px;
      padding: 6px 12px;
    }
  }
`

export const HomeWrap = styled.div`
  margin: auto;
  overflow: auto;
  height: 100%;
  color: white;
  background: black;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    max-width: 1080px;
    margin: auto;
  }
  .banner {
    /* height: 60%; */
    ${props => {
      if(props.width<500) {
        return 'width: 100%;'
      } else {
        return 'width: 70%;'
      }
    }
    }
    margin: auto;
    background: #f0f0f0;
    img {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: -4px;
    }
  }
`

export const ButtonNav = styled.div`
  border-style: none;
  padding: 0;
  background: none;
  color: #F7C331;
  font-size: 18px;
  position: relative;
  max-width:100%;
  height:100%;
  img {
    object-fit: cover;
    width: 100%;
    max-height: 90%;
  }
  ${props => {
    if(!props.focus){
      return `filter: opacity(0.5);`
    }
  }
}
`
