import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { fetchData, lotterySelector, clearState, addData, getAjarnImg, getTypeImg } from './LotterySlice'
import { TableWrapper } from './styles'
function Poy(props) {
  const dispatch = useDispatch()
  const state = useSelector(lotterySelector)
  const [newValue, setValue] = React.useState({
    date: "",
    draw_number: "",
    lotto_type: "",
    number_1digit: "",
    number_2digits: "",
    number_3digits: "",
    source: "",
  })
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const { isFetching, isSuccess, isError, errorMessage, data, type, ajarn, imgTypeArr } = state
  React.useEffect(() => {
    dispatch(fetchData('poy'))
    dispatch(getAjarnImg())
    dispatch(getTypeImg())
  }, []);
  const onSubmit = (data) => {
    dispatch(addData({type: 'poy', data}))
    dispatch(fetchData('poy'))
    reset({
      date: "",
      draw_number: "",
      lotto_type: "",
      number_1digit: "",
      number_2digits: "",
      number_3digits: "",
      source: "",
    })
  }
  return (<div>
    <h3>เพิ่มโพยประจำวัน</h3>
    <form onSubmit={handleSubmit(onSubmit)}>
    <TableWrapper>
    <thead>
      <tr className="tr-header">
        <td className="col">ประเภทหวย</td>
        <td className="col">อาจารย์</td>
        <td className="col">วันที่</td>
        <td className="col">งวดที่</td>
        <td className="col">2 ตัว</td>
        <td className="col">3 ตัว</td>
        <td className="col">เลขวิ่ง</td>
      </tr>
      </thead>
      <tbody>
      {data.poy.map((item, index) => {

        const date = new Date(typeof(item.date)==='object'? item.date.toDate():item.date).toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
        return (<tr key={index}>
        <td>{item.lotto_type}</td>
        <td>{item.source}</td>
        <td>{date}</td>
        <td>{item.draw_number}</td>
        <td>{item.number_2digits}</td>
        <td>{item.number_3digits}</td>
        <td>{item.number_1digit}</td>
        </tr>)})}
        <tr>
          <td>
            <select {...register("lotto_type")} defaultValue={imgTypeArr[0]?.name}>
            {imgTypeArr?.map(i=> <option key={i.name} value={i.name}>{i.name}</option>)}
            </select>
          </td>
          <td>
            <select {...register("source")} defaultValue={ajarn[0]?.name}>
            {ajarn?.map(i=> <option key={i.name} value={i.name}>{i.name}</option>)}
            </select>
          </td>
          <td><input type="date" {...register("date", { required: true })} /></td>
          <td><input {...register("draw_number", { required: true })} /></td>
          <td><input {...register("number_2digits", { required: true, maxLength: 2 })} /></td>
          <td><input {...register("number_3digits", { required: true, maxLength: 3 })} /></td>
          <td><input {...register("number_1digit", { required: true, maxLength: 1 })} /></td>
        </tr>
      </tbody>
    </TableWrapper>
      <input type="submit" value="บันทึก"/>
    </form>
    <div>
      {/*<button>เพิ่มบรรทัด</button>*/}
    </div>
  </div>)
}

export default Poy
