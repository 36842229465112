import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { signupUser, userSelector, clearState, listUser } from './UserSlice';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import {SignupWrap } from './styles'
const Signup = () => {
  const dispatch = useDispatch();
  const { register, formState: { errors }, setError, handleSubmit } = useForm();
  const history = useHistory();
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(
    userSelector
  );
  const onSubmit = (data) => {
    if(data.confirm===data.password){
      dispatch(signupUser(data));
    } else {
      setError("confirm", {
        type: "manual",
        message: "รหัสผ่านไม่ตรงกัน",
      });
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearState())
    };
  }, []);
  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      history.push('/');
    }
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);
  return (
    <Fragment>
      <SignupWrap error={errors}>
        <div>
          <h2>
            เพิ่มผู้ใช้งานใหม่
          </h2>
        </div>
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
          >
            <div className="form-input">
              ชื่อผู้ใช้
              <input {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "โปรดใส่อีเมลล์ให้ถูกต้อง"
                  }
                })}
                type="email" /></div>
            <div className="form-input">รหัสผ่าน<input type="password" {...register("password", { required: "Please enter your first name." })} /></div>
            <div className="form-input" id="confirm">
              ยืนยันรหัสผ่าน
              <input type="password"
                {...register("confirm",
                  { required: "Please enter your first name." })} /></div>
            <input type="submit" value="บันทึก"/>
          </form>
      </SignupWrap>
    </Fragment>
  );
};
export default Signup;
