import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { fetchData, lotterySelector, clearState, addData, getTypeImg } from './LotterySlice'
import { TableWrapper } from './styles'
function Poy(props) {
  const dispatch = useDispatch()
  const state = useSelector(lotterySelector)
  const [newValue, setValue] = React.useState({
    date: "",
    draw_number: "",
    lotto_type: "",
    number_1digit: "",
    number_2digits: "",
    number_3digits: "",
    source: "",
  })
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const { isFetching, isSuccess, isError, errorMessage, data, type, imgTypeArr } = state
  React.useEffect(() => {
    dispatch(fetchData('poy'))
    dispatch(fetchData('lottery_result'))
    dispatch(getTypeImg())

  }, []);
  const onSubmit = (add_data) => {
    dispatch(addData({type: 'lottery_result', data: add_data}))
    //check matching stat with poy for each ajarn that match date period and predict number
    //create new data for each match then push to stat
    // console.log(data.poy);
    const filtered = data.poy.filter(item => {
      // console.log(item.lotto_type,':',add_data.lotto_type,item.date,':',add_data.date,item.draw_number,':',add_data.draw_number);
      return item.lotto_type===add_data.lotto_type&&item.date===add_data.date&&item.draw_number===add_data.draw_number
    })
    // console.log('filtered:',filtered);
    let newStat = []
    for (let i in filtered) {
      const item = {
        lotto_type: filtered[i].lotto_type,
        date: filtered[i].date,
        draw_number: filtered[i].draw_number,
        source: filtered[i].source,
        number_1digit: filtered[i].number_1digit===add_data.number_1digit? 'Correct':'Incorrect' ,
        number_2digits: filtered[i].number_2digits===add_data.number_2digits? 'Correct':'Incorrect' ,
        number_3digits: filtered[i].number_3digits===add_data.number_3digits? 'Correct':'Incorrect' ,
      }
      newStat.push(item)
    }
    // console.log('NewStat:',newStat);
    dispatch(addData({type: 'stat', data: newStat}))
    dispatch(fetchData('lottery_result'))
    reset({
      date: "",
      draw_number: "",
      lotto_type: "",
      number_1digit: "",
      number_2digits: "",
      number_3digits: "",
    })
  }
  return (<div>
    <h3>เพิ่มผลหวย</h3>
    <form onSubmit={handleSubmit(onSubmit)}>
    <TableWrapper>
    <thead>
      <tr className="header">
        <td className="col">ประเภทหวย</td>
        <td className="col">วันที่</td>
        <td className="col">งวดที่</td>
        <td className="col">2 ตัว</td>
        <td className="col">3 ตัว</td>
        <td className="col">เลขวิ่ง</td>
      </tr>
      </thead>
      <tbody>
      {data.lottery_result.map((item, index) => {

        const date = new Date(typeof(item.date)==='object'? item.date.toDate():item.date).toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
        return (<tr key={index}>
        <td>{item.lotto_type}</td>
        <td>{date}</td>
        <td>{item.draw_number}</td>
        <td>{item.number_2digits}</td>
        <td>{item.number_3digits}</td>
        <td>{item.number_1digit}</td>
        </tr>)})}
        <tr>
          <td>
            <select {...register("lotto_type")}>
            {imgTypeArr?.map(i=> <option key={i.name} value={i.name}>{i.name}</option>)}
            </select>
          </td>
          <td><input type="date" {...register("date", { required: true })} /></td>
          <td><input {...register("draw_number", { required: true })} /></td>
          <td><input {...register("number_2digits", { required: true, maxLength: 2 })} /></td>
          <td><input {...register("number_3digits", { required: true, maxLength: 3 })} /></td>
          <td><input {...register("number_1digit", { required: true, maxLength: 1 })} /></td>
        </tr>
      </tbody>
    </TableWrapper>
      <input type="submit" value="บันทึก"/>
    </form>
    <div>
      {/*<button>เพิ่มบรรทัด</button>*/}
    </div>
  </div>)
}

export default Poy
