import React from 'react'
import styled from 'styled-components'

const CardWrap = styled.div`
  background: grey;
  height: 150px;
  width: 150px;
  display: inline-block;
  margin: 12px;
  line-height: 150px;
  background-size: contain;
  background-image: url(${props=>props.url});
  ${props => {if(props.name){
    return `::after {
      content: '${props.name}';
      font-size: 18px;
      color: ${props.url&&props.name? '#faebd700': 'white'};
    }`
  }}}
  img {width: 100%;}
`

const CardContain = styled.div`
  max-width: 860px;
  margin: auto;
`
function Card(props) {
  let result = []
  const handleClick = e => {
    props.setSensei(e.target.id)
  }
  for(let i in props.sensei) {
    const img = props.img.find(o=> {
      return o.name===i})||{imgUrl: ''}
    result.push(<CardWrap name={i} url={img?.imgUrl} key={i} id={i} onClick={handleClick}>
      </CardWrap>)
  }

  return (<CardContain>{result}</CardContain>)
}

export default Card

export function CardType(props) {
  let result = []
  const handleClick = e => {
    props.setLottoType(e.target.id)
  }
  for(let i in props.type) {
    const img = props.img.find(o=> {
      return o.name===i})||{imgUrl: ''}
    result.push(<CardWrap name={i} url={img?.imgUrl} key={i} id={i} onClick={handleClick}>
      </CardWrap>)
  }

  return (<CardContain>{result}</CardContain>)
}
