import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './features/User/Login';
// import Signup from './features/User/Signup';
import Home from './features/Lottery/Home'
import Dashboard from './features/User/Dashboard';
import { PrivateRoute } from './helpers/PrivateRoute';
import 'antd/dist/antd.css'
function App() {
  return (
    <div className="App" style={{
      height: '100vh',
      background: '#f0f0f0',
      color: 'black'
    }}>
      <Router>
        <Switch>
          <Route exact component={Home} path="/" />
          <Route exact component={Login} path="/login" />
          <PrivateRoute exact component={Dashboard} path="/admin" />
        </Switch>
      </Router>
    </div>
  );
}
export default App;
