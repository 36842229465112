import styled from 'styled-components'

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 12px; */
  border-bottom: 1px solid #f0f0f0;
`

export const MenuWrap = styled.div`
  display: flex;
  height: 100%;
`
export const Container = styled.div`
  /* height: 100vh; */
  padding: 32px;
      /* margin: 12px; */
  height: calc(100%);
`

export const MenuItem = styled.div`
  padding: 12px 6px;
  text-align: left;
  ${props=> {
    if(props.current===props.id) {
      return 'background: orange; color: white;'
    }
  }}
`

export const SignupWrap = styled.div`
  .form-input {
    display: grid;
    grid-template: 1fr /1fr 1fr 1fr;
  }
  #confirm {
    ${props=> {
      if(props.error.confirm) {
        return `::after {
          content: '${props.error.confirm.message}';
          color: red;
          font-size: 12px;
        }`
      }
    }}
  }
`
