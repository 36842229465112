import React from 'react'
import { firebase_store, db } from '../../firebase'
import styled from 'styled-components'
import { notification } from 'antd'
const PopupWrap = styled.div`
  padding: 16px 32px;
  .header { display: flex;}
    section {
      display: grid;
      grid-template: 1fr 1fr /1fr 1fr;
      text-align: start;
      grid-gap: 20px;
      margin-bottom: 12px;
      div {
        margin-left: 36px;
      }
    }
    button {
      background: #F7C331;
      border: none;
      padding: 6px 32px;
      border-radius: 4px;
    }
`

function Chanel(props) {
  return (
    <div>
      <UploadModule id='pop1'/>
      <UploadModule id='pop2'/>
      <UploadModule id='pop3'/>
      <UploadModule id='pop4'/>
      <UploadModule id='pop5'/>
      <UploadModule id='pop6'/>
      <UploadModule id='pop7'/>
      <UploadModule id='pop8'/>
    </div>
  )
}

export default Chanel


function UploadModule(props) {
  const [imageAsFile, setPopImageAsFile] = React.useState('')
  const [link, setPopLink] = React.useState('')
  const popLinkHandler = e => {
    setPopLink(e.target.value)
  }
  const handlePopupFile = e => {
     const image = e.target.files[0]
     setPopImageAsFile(imageFile => (image))
 }
 const uploadPopFile = async e => {
   e.preventDefault()
   if(imageAsFile === '') {
     console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
   }
   const uploadTask = firebase_store.child(`${e.target.id}.jpg`).put(imageAsFile)
   uploadTask.on('state_changed',
   (snapShot) => {
     console.log(snapShot)
   }, (err) => {
     console.log(err)
   })

   const popRef = db.collection('popup').doc(props.id)
   const res = await popRef.update({link: link})
   notification.success({
     message: 'Added data success',
     description:
       `Image ${e.target.id} has been added.`,
   })
 }
  return (
    <PopupWrap>
      <h3 className="header">Popup {props.id}</h3>
      <section>
        <div>รูป popup</div> <input type='file' onChange={handlePopupFile}/>
        <div>Link bit.ly</div> <input type='text' onChange={popLinkHandler}/>
      </section>
      <button id={props.id} onClick={uploadPopFile}>บันทึก</button>
    </PopupWrap>
  )
}
