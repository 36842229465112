import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { userSlice } from './features/User/UserSlice'
import { lotterySlice } from './features/Lottery/LotterySlice'
import { webSlice } from './features/Web/WebSlice'
export default configureStore({
  reducer: {
    user: userSlice.reducer,
    lottery: lotterySlice.reducer,
    web: webSlice.reducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
