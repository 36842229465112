import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { addImgUrl } from './WebSlice'
import { firebase_store } from '../../firebase.js'

function WebEdit(props) {
  const [imageAsFile, setImageAsFile] = React.useState('')//file
  const [imageAjarnAsFile, setImageAjarnAsFile] = React.useState('')//ajarn file
  const [imageTypeAsFile, setImageTypeAsFile] = React.useState('')//type file
  const [fileName, setFileName] = React.useState('')//ajarn filename
  const [fileTypeName, setFileTypeName] = React.useState('')//type filename
  const [ajarnName, setAjarnName] = React.useState('')//ajarn name
  const [typeName, setTypeName] = React.useState('')//type name
  const dispatch = useDispatch();
  const handleImageAsFile = (e) => {
     const image = e.target.files[0]
     setImageAsFile(imageFile => (image))
 }
 const nameHandler = e => {
   setAjarnName(e.target.value)
 }
 const typeNameHandler = e => {
   setTypeName(e.target.value)
 }
 const handleTypeImageAsFile = e => {
    const image = e.target.files[0]
    setFileTypeName(e.target.files[0].name)
    setImageTypeAsFile(imageFile => (image))
}
 const handleAjarnImageAsFile = e => {
    const image = e.target.files[0]
    setFileName(e.target.files[0].name)
    setImageAjarnAsFile(imageFile => (image))
}
  const handleBannerUpload = e => {
        e.preventDefault()
      console.log('start of upload')
      // async magic goes here...
      if(imageAsFile === '') {
        console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
      }
      const uploadTask = firebase_store.child(`banner.jpg`).put(imageAsFile)
      //initiates the firebase side uploading
      uploadTask.on('state_changed',
      (snapShot) => {

        //takes a snap shot of the process as it is happening
        console.log(snapShot)
      }, (err) => {
        //catches the errors
        console.log(err)
      })
      }
    const handleAjarnUpload = e => {
      e.preventDefault()
      console.log('start of upload')
      // async magic goes here...
      if(imageAjarnAsFile === '') {
        console.error(`not an image, the image file is a ${typeof(imageAjarnAsFile)}`)
      }
      const uploadTask = firebase_store.child(`${fileName}`).put(imageAjarnAsFile)
      uploadTask.on('state_changed', // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            console.log('Upload is paused');
            break;
          case 'running': // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {console.log(error)},
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          dispatch(addImgUrl({name: ajarnName, imgUrl:downloadURL, type: 'ajarn'}))
        });
      })
    }
    const handleTypeUpload = e => {
      e.preventDefault()
      console.log('start of upload')
      // async magic goes here...
      if(imageTypeAsFile === '') {
        console.error(`not an image, the image file is a ${typeof(imageTypeAsFile)}`)
      }
      const uploadTask = firebase_store.child(`${fileTypeName}`).put(imageTypeAsFile)
      uploadTask.on('state_changed', // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            console.log('Upload is paused');
            break;
          case 'running': // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {console.log(error)},
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          dispatch(addImgUrl({name: typeName, imgUrl:downloadURL, type: 'lotto_type'}))
        });
      })
    }
  return (<div>
    <div>
      <div>เปลี่ยน banner</div>
      <input type='file' onChange={handleImageAsFile}/>
      <button onClick={handleBannerUpload}>บันทึก</button>
    </div>
    <hr/>
    <div>
      <div>เพิ่มอาจารย์</div>
      <div>ชื่ออาจารย์ <input type='text' onChange={nameHandler}/></div>
      <div>รูป Icon <input type='file' onChange={handleAjarnImageAsFile}/></div>
      <button onClick={handleAjarnUpload}>บันทึก</button>
    </div>
    <hr/>
    <div>
      <div>เพิ่มประเภทหวย</div>
      <div>ชื่อหวย <input type='text' onChange={typeNameHandler}/></div>
      <div>รูป Icon <input type='file' onChange={handleTypeImageAsFile}/></div>
      <button onClick={handleTypeUpload}>บันทึก</button>
    </div>
  </div>)
}

export default WebEdit
