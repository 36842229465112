import React from 'react'
import styled from 'styled-components'

const PopupWrap = styled.div`
  margin-bottom: 3px;
  min-height: 100px;
  img{width: 100%}
`
function Popup(props) {
  return (
    <PopupWrap>
      {props.src? <a href={props.link}>
        <img src={props.src}/>
      </a>: 'ว่าง'}
    </PopupWrap>
  )
}

export default Popup

export const PopupContainer = styled.div`
  ${props=> {
    if(props.width < 500) {
      return `
      display: flex;
      `
    } else {
      return `
      position: absolute;
      top: 0px;
      ${(props.width<900)?'width: 90px;':'width: 130px;'}
      ${(props.left)?'left: 0px;':'right: 0px;'}
      `
    }
  }
  }
`
