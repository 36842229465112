import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firebase_app } from '../../firebase'
import { notification } from 'antd';
export const fetchData = createAsyncThunk(
  'lottery/fetchData',
  async (type="stat", thunkAPI) => {
    const response = await firebase_app.firestore().collection(type).orderBy('date').get()
    let result = []
    response.forEach((doc) => {
        result.push(doc.data())
    })
    return {result, type}
  }
)

export const getAjarnImg = createAsyncThunk(
  'lottery/getAjarnImg',
  async (type="ajarn", thunkAPI) => {
    const response = await firebase_app.firestore().collection(type).get()
    let result = []
    response.forEach((doc) => {
        result.push(doc.data())
    })
    return {result}
  }
)

export const getTypeImg = createAsyncThunk(
  'lottery/getTypeImg',
  async (type="lotto_type", thunkAPI) => {
    const response = await firebase_app.firestore().collection(type).get()
    let result = []
    response.forEach((doc) => {
        result.push(doc.data())
    })
    return {result}
  }
)

export const addData = createAsyncThunk(
  'lottery/addData',
  async ({type, data}, thunkAPI) => {
    let result = []
    if(type ==='stat') {
      let db = firebase_app.firestore();
      let batch = db.batch()
      data.forEach((doc) => {
        let docRef = db.collection(type).doc()
        batch.set(docRef, doc)
      })
      batch.commit()
    }
    else {
      const response = await firebase_app.firestore().collection(type).add({...data})
      notification.success({
        message: 'Added data success',
        description:
          `${type} has been added.`,
      })
      response.forEach((doc) => {
          result.push(doc.data())
      })
      console.log(response);
    }
    return {result, type}
  }
)


export const lotterySlice = createSlice({
  name: 'lottery',
  initialState: {
    data: {poy: [], stat: [], lottery_result: []},
    type: '',
    ajarn: [],
    imgTypeArr: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.type = payload.type
      state.data[payload.type] = payload.result;
    },
    [fetchData.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getAjarnImg.pending]: (state) => {
      state.isFetching = true;
    },
    [getAjarnImg.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.ajarn = payload.result;
    },
    [getTypeImg.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.imgTypeArr = payload.result;
    },
    [getAjarnImg.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    [addData.pending]: (state) => {
      state.isFetching = true;
    },
    [addData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [addData.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState } = lotterySlice.actions;

export const lotterySelector = (state) => state.lottery;
