import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, clearState } from './UserSlice';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Signup from './Signup'
import WebEdit from '../Web'
import Chanel from '../Web/Chanel'
import Poy from '../Lottery/Poy'
import Result from '../Lottery/Result'

import {HeaderWrap, MenuWrap, Container,MenuItem } from './styles'
const Dashboard = () => {
  const history = useHistory();
  const [menu, setMenu] = React.useState('edit-admin');
  const dispatch = useDispatch();
  const { isFetching, isError } = useSelector(userSelector);
  useEffect(() => {
    // dispatch(fetchUserBytoken({ token: localStorage.getItem('token') }));
  }, []);

  const { email } = useSelector(userSelector);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      history.push('/login');
    }
  }, [isError]);

  const onLogOut = () => {
    localStorage.removeItem('token');

    history.push('/');
  };
  const menuClickHandler = event => {
    setMenu(event.target.id)
  }

  const renderContent = menu => {
    let result
    switch (menu) {
      case 'edit-admin':
        result = <Signup/>
        break;
      case 'edit-web':
        result = <WebEdit/>
        break;
      case 'add-poy':
        result = <Poy />
        break;
      case 'add-result':
        result = <Result />
        break;
      case 'add-chanel':
        result = <Chanel />
        break;
      default:
    }
    return result
  }
  return (
    <Container>
      {isFetching ? (
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      ) : (
        <Fragment>
          <HeaderWrap>
            <Link to="">Huaydedtunjai.com</Link> <h3>{email}</h3>
            <button onClick={onLogOut} className="">Log Out</button>
          </HeaderWrap>
          <MenuWrap>
            <div>
              <MenuItem current={menu} onClick={menuClickHandler} id="edit-admin">แก้ไขแอดมิน</MenuItem>
              <MenuItem current={menu} onClick={menuClickHandler} id="edit-web">แก้ไขหน้าเว็บ</MenuItem>
              <MenuItem current={menu} onClick={menuClickHandler} id="add-poy">เพิ่มโพย</MenuItem>
              <MenuItem current={menu} onClick={menuClickHandler} id="add-result">เพิ่มผลหวย</MenuItem>
              <MenuItem current={menu} onClick={menuClickHandler} id="add-chanel">เพื่มช่องทางการแทงหวย</MenuItem>
            </div>
            <div  style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              overflow: 'auto',
              marginBottom: '100px'
            }}>
              {renderContent(menu)}
            </div>
          </MenuWrap>
        </Fragment>
      )}
    </Container>
  );
};

export default Dashboard;
