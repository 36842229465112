import React, {useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { firebase_store } from '../../firebase'
import { fetchData, lotterySelector, clearState, getAjarnImg, getTypeImg } from './LotterySlice'
import { getLink, webSelector } from '../Web/WebSlice'
import { TableWrapper, HomeWrap, ButtonNav } from './styles'
import useWindowDimensions from '../../helpers/windowDimentsion'
import button1 from '../../assets/menu-01.png'
import button2 from '../../assets/menu-02.png'
import button3 from '../../assets/menu-03.png'
import Card, { CardType } from './Card'
import Popup, {PopupContainer} from '../Web/Popup'
const Home = () => {
  const dispatch = useDispatch();
  const state = useSelector(lotterySelector)
  const stateWeb = useSelector(webSelector)
  const [sensei, setSensei] = React.useState('')
  const [imgUrl, setUrl] = React.useState('')
  const [imgPop1, setUrlPop1] = React.useState('')
  const [imgPop2, setUrlPop2] = React.useState('')
  const [imgPop3, setUrlPop3] = React.useState('')
  const [imgPop4, setUrlPop4] = React.useState('')
  const [imgPop5, setUrlPop5] = React.useState('')
  const [imgPop6, setUrlPop6] = React.useState('')
  const [imgPop7, setUrlPop7] = React.useState('')
  const [imgPop8, setUrlPop8] = React.useState('')
  const [lottoType, setLottoType] = React.useState('')
  const [typeF, setFType] = React.useState('poy')
  const { isFetching, isSuccess, isError, errorMessage, data, type, ajarn, imgTypeArr } = state
  useEffect(() => {
    dispatch(fetchData('poy'))
    getImage()
    dispatch(getLink())
    dispatch(getAjarnImg())
    dispatch(getTypeImg())
  }, []);
  const getImage = async () => {
    const request = await firebase_store.child(`banner.jpg`).getDownloadURL()
    setUrl(request)
    const request1 = await firebase_store.child(`pop1.jpg`).getDownloadURL()
    setUrlPop1(request1)
    const request2 = await firebase_store.child(`pop2.jpg`).getDownloadURL()
    setUrlPop2(request2)
    const request3 = await firebase_store.child(`pop3.jpg`).getDownloadURL()
    setUrlPop3(request3)
    const request4 = await firebase_store.child(`pop4.jpg`).getDownloadURL()
    setUrlPop4(request4)
    const request5 = await firebase_store.child(`pop5.jpg`).getDownloadURL()
    setUrlPop5(request5)
    const request6 = await firebase_store.child(`pop6.jpg`).getDownloadURL()
    setUrlPop6(request6)
    const request7 = await firebase_store.child(`pop7.jpg`).getDownloadURL()
    setUrlPop7(request7)
    const request8 = await firebase_store.child(`pop8.jpg`).getDownloadURL()
    setUrlPop8(request8)
  }

  const handleFetch = (data) => {
    setSensei('')
    setLottoType('')
    setFType(data.target.id)
    dispatch(clearState());
    dispatch(fetchData(data.target.id))
  }
  const { width } = useWindowDimensions();
  const renderItem = (items, type) => {
    let result
    switch (type) {
      case 'stat':
    // Split array into object by lotto type <CardType/>
        const groupedType = items.poy.reduce(function(results, org) {
          (results[org.lotto_type] = results[org.lotto_type] || []).push(org);
          return results;
        }, {})
    // Filter stat array by lotto type
        const filteredStatType = items.stat.filter(i => i.lotto_type===lottoType)
        const filteredPoyType = items.poy.filter(i => i.lotto_type===lottoType)
        let latest_date = new Date()
    // Find latest date from filtered stat selected type
        if(filteredStatType.length>0) {
          latest_date = new Date(Math.max(...filteredStatType.map(e => new Date(e.date))))
        } else if(filteredPoyType.length> 0){
          latest_date = new Date(Math.max(...filteredPoyType.map(e => new Date(e.date))))
        }
    // Split selected lotto type stat by date
        // const groupByDate = filteredStatType.reduce(function(results, org) {
        //   (results[org.date] = results[org.date] || []).push(org);
        //   return results;
        // }, {})

        const filterdByLatestDate = lottoType? groupedType[lottoType].filter(j => {
          return j.date === latest_date.toISOString().slice(0, 10)
        }):[]
        // split by source
        const groupBySorce = filteredStatType.reduce(function(results, org) {
          (results[org.source] = results[org.source] || []).push(org);
          return results;
        }, {})

        //count stat
        let countResult = {}
        for (let ajarn in groupBySorce) {
          let count = 0
          for (let predict in groupBySorce[ajarn]) {
            if(groupBySorce[ajarn][predict].number_3digits=='Correct'||groupBySorce[ajarn][predict].number_2digits=='Correct'||groupBySorce[ajarn][predict].number_1digit=='Correct') {
              count = count+1
            }
          }
          countResult[ajarn] = count
        }

        result = lottoType? (<>
          <h3>เลขเด็ด{lottoType} วันที่
          {latest_date.toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
          </h3>
          <TableWrapper width={width}>
          <thead>
            <tr className="tr-header">
              <td className="col">อาจารย์</td>
              <td className="col">สถิติ</td>
              <td className="col">งวดที่</td>
              <td className="col">2 ตัว</td>
              <td className="col">3 ตัว</td>
              <td className="col">เลขวิ่ง</td>
            </tr>
            </thead>
            <tbody>
              {filterdByLatestDate.map((item,index) => {
                const date = new Date(item.date).toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
                return (<tr key={index}>
                <td>{item.source}</td>
                <td>{countResult[item.source]||0} งวด</td>
                <td>{item.draw_number}</td>
                <td>{item.number_2digits}</td>
                <td>{item.number_3digits}</td>
                <td>{item.number_1digit}</td>
                </tr>)})}
              </tbody>
          </TableWrapper></>) : <CardType img={imgTypeArr} setLottoType={setLottoType} type={groupedType} />
        break;
        case 'lottery_result':
          const groupByType = items.lottery_result.reduce(function(results, org) {
            (results[org.lotto_type] = results[org.lotto_type] || []).push(org);
            return results;
          }, {})
          let latestDate = {}
          let currentArr = []
          for (let type in groupByType) {
            let group_latest_date = new Date(Math.max(...groupByType[type].map(e => new Date(e.date))))
            latestDate[type] = group_latest_date.toISOString().slice(0, 10)
            currentArr.push(...groupByType[type].filter(i => i.date==latestDate[type]))
          }

          result = (
            <TableWrapper width={width}>
            <thead>
              <tr className="tr-header">
                <td className="col">วันที่</td>
                <td className="col">งวดที่</td>
                <td className="col">ประเภทหวย</td>
                <td className="col">เลขวิ่ง</td>
                <td className="col">2 ตัว</td>
                <td className="col">3 ตัว</td>
              </tr>
              </thead>
              <tbody>
              {currentArr.map((item, index) => {
                const date = new Date(item.date).toLocaleDateString('th-TH', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
                return (
                  <tr key={index}>
                    <td>{date}</td>
                    <td>{item.draw_number}</td>
                    <td>{item.lotto_type}</td>
                    <td>{item.number_1digit}</td>
                    <td>{item.number_2digits}</td>
                    <td>{item.number_3digits}</td>
                  </tr>)})}
              </tbody>
            </TableWrapper>
          )
          break;
          case 'poy':
            const groupedSensei = items.poy.reduce(function(results, org) {
              (results[org.source] = results[org.source] || []).push(org);
              return results;
            }, {})
            let latestDatePoy = {}
            for (let ajarn in groupedSensei) {
              let group_latest_date = new Date(Math.max(...groupedSensei[ajarn].map(e => new Date(e.date))))
              latestDatePoy[ajarn] = group_latest_date.toISOString().slice(0, 10)
              groupedSensei[ajarn] = groupedSensei[ajarn].filter(i => i.date==latestDatePoy[ajarn])
            }
            result = sensei? (
              <>
              <h3>เลขเด็ด{sensei}</h3>
              <TableWrapper width={width}>
              <thead>
                <tr className="tr-header">
                  <td className="col">หวย</td>
                  <td className="col">วันที่</td>
                  <td className="col">งวดที่</td>
                  <td className="col">เลขวิ่ง</td>
                  <td className="col">2 ตัว</td>
                  <td className="col">3 ตัว</td>
                </tr>
                </thead>
                <tbody>
                {groupedSensei[sensei].map((item, index) => {
                  const date = new Date(item.date).toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                  return (
                    <tr key={index}>
                      <td>{item.lotto_type}</td>
                      <td>{date}</td>
                      <td>{item.draw_number}</td>
                      <td>{item.number_1digit}</td>
                      <td>{item.number_2digits}</td>
                      <td>{item.number_3digits}</td>
                    </tr>)})}
                  </tbody>
              </TableWrapper>
              </>
            ):<Card img={ajarn} setSensei={setSensei} sensei={groupedSensei}/>
            break;
      default:

    }
    return result
  }
  return (
    <HomeWrap type={typeF} width={width}>
      <div className="header">
        <div>Huaydedtunjai.com</div>
        <div>@ruayruay89</div>
      </div>
      <div className="banner">
        <img src={imgUrl} alt="UK flag" />
      </div>
      <div style={
        {
          width: '100%',
          display: 'grid',
          gridTemplate: '1fr/ 1fr 1fr 1fr',
          margin: 'auto',
          maxWidth: '500px'
        }
      }>
        <ButtonNav focus={typeF==='poy'}><img id="poy" onClick={handleFetch} src={button1}/></ButtonNav>
        <ButtonNav focus={typeF==='stat'}><img onClick={handleFetch} id="stat" src={button2}/></ButtonNav>
        <ButtonNav focus={typeF==='lottery_result'}><img onClick={handleFetch} id="lottery_result" src={button3}/></ButtonNav>
      </div>
      <div style={{position: 'relative'}}>
        {renderItem(data, type)}
        <PopupContainer width={width} left>
          <Popup src={imgPop1} link={stateWeb.data.pop1||''}/>
          <Popup src={imgPop2} link={stateWeb.data.pop2||''}/>
          <Popup src={imgPop3} link={stateWeb.data.pop3||''}/>
          <Popup src={imgPop4} link={stateWeb.data.pop4||''}/>
        </PopupContainer>
        <PopupContainer width={width} right>
          <Popup src={imgPop5} link={stateWeb.data.pop5||''}/>
          <Popup src={imgPop6} link={stateWeb.data.pop6||''}/>
          <Popup src={imgPop7} link={stateWeb.data.pop7||''}/>
          <Popup src={imgPop8} link={stateWeb.data.pop8||''}/>
        </PopupContainer>
      </div>
    </HomeWrap>
  )
}

export default Home
